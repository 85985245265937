import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { IdleTimerProvider } from 'react-idle-timer';
import AutenticarService from "./service/AutenticarService";
import Config from "./config/Config";

const Auth = lazy(() => import("./component/auth"));
const RecuperarSenha = lazy(() => import("./component/recuperar_senha"));
const Admin = lazy(() => import("./Admin"));
const ClientePre = lazy(() => import("./component/cliente/ClientePre"));
const RedefinirSenha = lazy(() => import("./component/redefinir_senha")); 

const Public = () => {
  const onIdle = () => {
    AutenticarService.logout();
  };  

  return (
      <Router basename={process.env.REACT_APP_BASE_PATH}>
        <Suspense
          fallback={
            <div className="d-flex justify-content-center mt-5 pt-5">
              {" "}
              <CircularProgress />{" "}
            </div>
          }
        >
          <IdleTimerProvider          
              timeout={15 * 60 * 1000}
              onIdle={onIdle}
          >
            <Switch>
              <Route path={Config.routes.login} component={Auth} />
              <Route path={Config.routes.recuperarSenha} component={RecuperarSenha} />
              <Route path={Config.routes.redefinirSenha} component={RedefinirSenha} />
              <Route path={Config.routes.cadastro} component={ClientePre} />
              <Route path={Config.routes.root} component={Admin} />                        
            </Switch>
          </IdleTimerProvider>
        </Suspense>
      </Router>
  );
}

export default Public;
