const config = {};

config.mobile = window.innerWidth < 577 ? true : false;
config.versao = "01.02.002/004";
config.atualizado = "12/12/2024";
config.urlApi = process.env.REACT_APP_BACKEND_URL;

console.log(`Versão: ${config.versao} atualiza em ${config.atualizado}`)

var appBasePath = process.env.REACT_APP_BASE_PATH;
if (appBasePath.length === 1) {
  appBasePath = ''
}
config.basePath = appBasePath || '';

config.routes = {
  login: "/login",
  recuperarSenha: "/recuperar-senha",
  root: "/",
  pedido: "/pedido",
  cadastro: "/pre-cadastro",
  redefinirSenha: "/redefinir-senha",
  alterarSenha: "/alterar-senha",
  perfil: "/perfil",
  administrativo: "/administrativo",
  reiniciosenha: "/reinicio-senha",
  administrativoEditar: "/administrativoeditar",
};

export default config;
