import { createTheme } from '@material-ui/core/styles';

export const ThemeGreen = createTheme({
    palette: {
        primary: {                      
            main: '#2F7346'
        }
    },

    props: {
        MuiTextField: {
            variant: 'outlined',
            fullWidth: true                       
        },

        MuiSelect: {
            variant: 'outlined',
            fullWidth: true
        },        
    }
});


export const ThemeBlue = createTheme({
    palette: {
        primary: {                      
            main: '#3e69e8'
        }
    },

    props: {
        MuiTextField: {
            variant: 'outlined',
            fullWidth: true            
        },

        MuiSelect: {
            variant: 'outlined',
            fullWidth: true
        },        
    }
});
